(<template>
  <section class="basic-filters">
    <div class="basic-filters__wrapper">
      <button class="sk-btn sk-btn--default basic-filters__filters-btn"
              @click="$emit('openfilters')">{{ $gettext('More filters') }}</button>
      <sk-select :items-list="langsList"
                 :preselected-value="langTo"
                 :default-value="langSelectText"
                 :removed-item="langFrom"
                 class="basic-filters__lang-select"
                 @csvaluechanged="catchLangTo" />
      <button class="sk-btn sk-btn--default basic-filters__submit-btn"
              @click="$emit('submitfilters')">{{ $gettext('Search') }}</button>
    </div>
    <section class="basic-filters__mobile-elements">
      <button class="basic-filters__mobile-btn"
              @click="$emit('openfilters')">{{ $gettext('Filters') }}</button>
    </section>
  </section>
</template>)

<script>
  export default {
    props: {
      langTo: {
        type: [String, Number],
        default: ''
      },
      langFrom: {
        type: [String, Number],
        default: ''
      }
    },
    computed: {
      langSelectText() { return this.$gettext('Select language'); },
      langsList() {
        const langsList = JSON.parse(JSON.stringify(this.$store.state.selectsData.language));
        langsList.unshift({id: 'all', name: this.$gettext('All')});
        return langsList;
      }
    },
    methods: {
      catchLangTo(value) {
        this.$emit('langchosen', value);
      }
    }
  };
</script>

<style>
  @media (max-width: 767px) {
    .basic-filters__lang-select .sk-select {
      width: 100%;
      margin-left: 0;
      border: 1px solid #a7acbe;
    }

    .basic-filters__lang-select .sk-select__value,
    .basic-filters__lang-select .sk-select__typing-input {
      padding: 0 10px;
    }
  }
</style>

<style scoped>
  .basic-filters {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px 10px;
  }

  .basic-filters__wrapper {
    display: flex;
    flex-grow: 1;
    width: auto;
    height: 35px;
  }

  .basic-filters__mobile-elements {
    display: none;
  }

  .basic-filters__filters-btn {
    position: relative;
    display: inline-block;
    flex-shrink: 0;
    width: auto;
    margin-right: auto;
    padding: 0 10px;
    padding-left: 30px;
  }

  .basic-filters__filters-btn::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 30px;
    background-image: url(~@assets/imgs/undefined_imgs/filters_ico_white.svg);
    background-position: 50% 50%;
    background-size: 17px auto;
    background-repeat: no-repeat;
  }

  .basic-filters__lang-select {
    flex-grow: 1;
    max-width: 320px;
    background-color: #fff;
  }

  .basic-filters__submit-btn {
    display: inline-block;
    flex-shrink: 0;
    width: auto;
    margin-left: 15px;
    padding: 0 13px;
  }

  .basic-filters__mobile-btn {
    position: relative;
    display: inline-block;
    width: auto;
    height: 35px;
    padding-right: 20px;
    font-weight: bold;
  }

  .basic-filters__mobile-btn::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 20px;
    background-image: url(~@assets/imgs/arrows/lang_details_expand_arrow.svg);
    background-position: 50% 50%;
    background-size: 10px auto;
    background-repeat: no-repeat;
  }

  @media (max-width: 767px) {
    .basic-filters {
      flex-wrap: wrap;
      max-width: initial;
      padding: 5px 15px 0;
      box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 1px 2px 0 rgba(0, 34, 102, 0.2);
    }

    .basic-filters__wrapper {
      max-width: initial;
    }

    .basic-filters__filters-btn {
      display: none;
    }

    .basic-filters__mobile-elements {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .basic-filters__lang-select {
      max-width: initial;
    }
  }
</style>
